import { NgModule } from "@angular/core";
import { NavbarComponent } from "./navbar.component";
import { NavlinkComponent } from "./navlink/navlink.component";
import { InformationComponent } from "./information/information.component";
import { LogoutComponent } from "./logout/logout.component";
import { LogoModule } from "../logo/logo.module";
import { CommonModule } from "@angular/common";
import { InformationPageComponent } from "src/app/views/information-page/information-page.component";
import { RouterModule } from "@angular/router";

@NgModule({
    declarations: [
        NavbarComponent,
        NavlinkComponent,
        InformationComponent,
        InformationPageComponent,
        LogoutComponent,
    ],
    imports: [
        CommonModule,
        LogoModule,
        RouterModule
    ],
    exports: [
        NavbarComponent
    ]
})
export class NavbarModule {}