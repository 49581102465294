<div class="flex gap-x-8 lg:gap-x-6 items-center group cursor-pointer lg:py-2 lg:hover:bg-secondary" (click)="logout()">
    
    <div class="flex lg:min-w-[var(--navbar-width)] lg:justify-center">
        <i class="pl-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
            </svg> 
        </i>
    </div>

    <div *ngIf="!onlyLetter">
        <p class="text-xl lg:text-base lg:uppercase lg:group-hover:scale-105 lg:transition-transform">
            Logout
        </p>
    </div>

</div>