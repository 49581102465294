import { Component, Input } from '@angular/core';
import { Navlink } from './navlink.model';

@Component({
  selector: 'app-navlink',
  templateUrl: './navlink.component.html',
  styleUrls: ['./navlink.component.css']
})
export class NavlinkComponent {

  @Input({required: true}) 
  link!: Navlink;

  @Input()
  onlyLetter!: boolean;

}
