import { Component } from '@angular/core';
import { InformationSection } from './information-section.model';

@Component({
  selector: 'app-information-page',
  templateUrl: './information-page.component.html',
  styleUrl: './information-page.component.css'
})
export class InformationPageComponent {

  protected templateSections: InformationSection[] = [
    {
      title:        "Ticket Information",
      description:  "To see all our up-to-date Ticket sales information please click the link below",
      link:         "https://www.chelseafc.com/en/ticket-information",
      background:   "bg-main",
    },
    {
      title:        "Matchday Information",
      description:  "To see all our up to date Matchday information and guides please click the link below",
      link:         "https://www.chelseafc.com/en/matchday-information-guide",
      background:   "bg-secondary",
    },
    {
      title:        "Contact Us",
      description:  "To contact Chelsea please click the link below and to see all our details or to complete a Contact Us form.",
      link:         "https://www.chelseafc.com/en/contact-us",
      background:   "bg-main lg:bg-secondary"
    },
    {
      title:        "FAQ’s",
      description:  "For more information, please click below and to see all of our FAQs.",
      link:         "https://www.chelseafc.com/en/faqs",
      background:   "bg-secondary lg:bg-main"
    },
    
    

  ]

}
