<main class="w-full min-h-[100dvh] h-full lg:min-h-screen lg:h-full bg-main/5 dark:bg-slate-900 overflow-hidden flex flex-col lg:grid lg:grid-cols-[auto_1fr]">
    <section>
      <app-navbar/>
    </section>
    
    <section class="flex-1 overflow-y-auto">
     <app-topbar/>
     <router-outlet/>
    </section>
</main>
