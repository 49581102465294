import { Component,inject, OnInit } from '@angular/core';
import { Configuration } from 'src/app/shared/models/configuration.model';
import { APP_CONFIG } from 'src/configuration/configuration';
import { LoginViews } from './login.model';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  private configuration: Configuration = inject(APP_CONFIG);
  private params:        Params        = inject(ActivatedRoute).snapshot.queryParams;

  protected appName!:    string;
  protected client!:     string;    
  protected background!: string;    
  protected view!:       LoginViews

  ngOnInit(): void {
    this.initComponent();
  }

  /**
   * Inicializa el componente. 
   * Mediante el Login Guard, cargamos el componente del Login y le añadimos, 
   * dependiendo la redirección, un queryParam u otro que determine que vista mostrar
   * al cargar el componente. Si no viene de redirección, de manera default carga la vista 
   * de login.
   * 
   * @see LoginGuard 
   */
  private initComponent(): void {
    
    // Seteamos las variables de la template con los datos de la configuración
    this.appName    = this.configuration.general.appName;
    this.client     = this.configuration.general.clientName;
    this.background = this.configuration.assets.background;

    // Instanciamos los params y comprobamos si existe token
    const params: LoginViews | undefined  = this.params['view'];

    // Si no hay params directamente cargamos el Login
    

    // Si los hay comprobamos cual es y actuamos en cada caso. Si vienen redirigidos de email deben traer token. 
    switch(params){
      
      case 'association':
      case 'reset':
        const token:  string | undefined = this.params['token'];
          token ? 
          this.view = params : 
          this.view = 'login';
      break;

      case 'new':
        this.view = params;
      break;

      default:
        this.view = 'login';
      break;
      
    }
    
  }

  protected get showLoginTexts(): boolean {
    return this.view !== 'association';
  }
  
  protected changeLoginView(view: LoginViews): void {
    this.view = view;
  }
}
