<form action="submit" class="flex flex-col form mt-4 lg:h-2/4 sm:justify-evenly">
    <div class="mb-5">
        <p class="text-xs md:text-sm text-darkgray">
            Do you have a Season Ticket or Membership?
            Enter your email and we will send you a confirmation email to register you account.
            <br><br>
            If you are creating a new account, enter your email to start your registration.
        </p>
    </div>
    <div class="flex flex-col">
        <label for="email">Email</label>
        <input [(ngModel)]="email" class="mb-3" type="email" name="email" id="email" placeholder="Your email">
        <button class="button" [disabled]="!isEmailEmpty" (click)="createNewAccount()">Check Email</button>
    </div>
    
</form>
<div class="mt-3 lg:mt-10">
    <button class="w-full text-main dark:text-secondary transition-transform hover:scale-95 font-main" (click)="showLoginForm()">
       <span class="text-center">Back to Login</span> 
    </button>
</div>