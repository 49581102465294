<div class="h-10 md:h-8 bg-secondary text-white overflow-hidden">
    
    <!-- WRAPPER -->
    <div class="relative flex items-center justify-between h-full px-5 md:px-0">
        
        <!-- ADMIN TEXT -->
        <p class="md:text-center md:w-full md:animate-pulse duration-1000 cursor-default">
            <span class="hidden md:inline-block">
                {{ adminName }} ·
            </span>
            You are logged in as an administrator
        </p>

        <!-- LOG OUT BUTTON -->
        <button class="md:absolute right-5 transition-all opacity-80 hover:opacity-100 hover:scale-95" (click)="openConfirmationModal()">
            <span class="font-main">
                Return
            </span>
        </button>

    </div>

</div>