import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Configuration } from 'src/app/shared/models/configuration.model';
import { APP_CONFIG } from 'src/configuration/configuration';
/**
 * ### Logo Component
 * Componente que carga el archivo de configuración e instancia una imagen pasando como src la ruta obtenida en dicho archivo. 
 * Se le pueden bindear eventos desde un componente padre. 
 */
@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})

export class LogoComponent {
  
  private configuration: Configuration = inject(APP_CONFIG);
  private router:        Router        = inject(Router);
  
  protected logo:        string        = this.configuration.logo;

  protected navigateToHome(): void {
    this.router.navigate(['/']);
  }
  
}
