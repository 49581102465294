<a [routerLink]="link.slug" routerLinkActive="linkActive">
    
    <!-- WRAPPER -->
    <div class="flex gap-x-8 lg:gap-x-6 font-main text-white text-2xl lg:text-base items-center my-4 lg:my-0 lg:py-2 group cursor-pointer w-fit lg:w-full lg:hover:bg-secondary">

        <!-- LETTER -->
        <div id="letter" class="group-hover:bg-secondary w-10 h-10 flex justify-center items-center lg:min-w-[var(--navbar-width)]">
            <span class="text-3xl lg:text-4xl">
                {{link.letter}}
            </span>
        </div>

        <!-- TEXT -->
        <div id="text" *ngIf="!onlyLetter" class="group-hover:underline lg:transition-transform lg:group-hover:no-underline lg:group-hover:scale-105">
            <span class="lg:uppercase ">
                {{ link.text }}
            </span>
        </div>

    </div>

</a>