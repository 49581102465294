<section class="bg-white h-full flex flex-col md:justify-center">

    <div class="grid md:grid-rows-2 md:grid-cols-2 container mx-auto p-5 gap-3 items-center overflow-y-auto lg:h-full">

        <div *ngFor="let section of templateSections; index as i" [ngClass]="section.background" class="w-full rounded-md shadow bg-main p-5 flex flex-col justify-between h-48 md:h-full">
          
            <div class="mt-3 lg:mt-5">
                <h4 class="text-white text-xl lg:text-3xl mb-3">
                    {{section.title}}
                </h4>

                <p class="text-lightgray text-sm mb-2 lg:mt-5 lg:text-lg xl:text-xl">
                    {{section.description}}
                </p>
            </div>

            <div class="w-full flex justify-end">
                <a [href]="section.link" target="_blank" class="button inline-block text-center bg-white w-4/12 text-secondary hover:bg-secondary hover:text-white">
                    Click Here
                </a>
            </div>

        </div>

    </div>

</section>