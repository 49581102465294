import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

/**
 * ### Auth Guard
 * Este Guard actúa en todo el ámbito de la app y es el responsable de detectar si el usuario
 * está logueado para poder navegar. En caso de no estarlo, lo devuelve al login.
 * @param route 
 * @param state 
 * @returns boolean
 */
export const authGuard: CanActivateFn = (route, state) => {
  
  const auth:   AuthService = inject(AuthService);
  const router: Router      = inject(Router);

  /**
   * Utilizando el método getUser del Auth Service recoge si existen datos de un user logueado. 
   * Si hay user, retorna true. En caso de no haberlo, devuelve al login y retorna false. 
   */
  return auth.getUser().then(
    user  =>  user ? true : router.navigate(['/login']).then(()=> false),
    error =>  {console.log(error); return false}
  )

};
