import { Component, inject, Renderer2 } from '@angular/core';
import { Configuration } from 'src/app/shared/models/configuration.model';
import { APP_CONFIG } from 'src/configuration/configuration';
import { Navlink } from './navlink/navlink.model';
import { Router } from '@angular/router';

@Component({
  host:{
    '(window:resize)': 'onResize($event)'
  },
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent {
  
  // SERVICES
  private configuration: Configuration = inject(APP_CONFIG);
  private renderer:      Renderer2     = inject(Renderer2);

  // STATE
  protected client:     string  = this.configuration.clientName;
  protected appName:    string  = this.configuration.appName;
  protected isOpen:     boolean = false;
  protected isExtended: boolean = false;
  protected isDesktop:  boolean = window.screen.width > 1024; //1024px is the treshold to change mobile to pc

  // ROUTES
  public readonly routes: Navlink[] = [
    {
      letter: "B",
      text:   "Buy Tickets",
      slug:   "buy-tickets"
    },
    {
      letter: "P",
      text:   "Buy Packages",
      slug:   "buy-packages",
    },
    {
      letter: "R",
      text:   "Reserved Tickets",
      slug:   "renew-tickets"
    },
    {
      letter: "T",
      text:   "Men's Ticket Exchange",
      slug:   "exchange"
    },
    {
      letter: "W",
      text:   "Women's T.Exchange",
      slug:   "women-texchange"
    },
    {
      letter: "F",
      text:   "Friends & Family",
      slug:   "friends-family"
    },
    {
      letter: "A",
      text:   "Account Details",
      slug:   "my-account"
    }
  ]

  // METHODS
  public navigate(): void {
    
    // Si estamos en mobile cerramos la navbar
    if(this.isOpen === true){
      this.toggleMobileMenu();
    }
    
    return;
  }

  protected toggleMobileMenu(): void {
    this.isOpen = !this.isOpen

    if(this.isOpen === true){
      this.renderer.addClass(document.body, 'overflow-hidden')
    }else{
      this.renderer.removeClass(document.body, 'overflow-hidden')
    }
    
  }
  
  private onResize(event: UIEvent): void {
    const ev = event.target as Window;
    this.isDesktop = ev.innerWidth >= 1024;
  }

}
