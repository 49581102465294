<form action="submit" class="flex flex-col form mt-4 h-full lg:h-2/4 justify-evenly">
    <div class="flex flex-col">
        <label for="email">Email Adress</label>
        <input autocomplete="on" [(ngModel)]="user" type="email" name="email" id="email" placeholder="Add your Email Adress">
    </div>
    
    <div class="flex flex-col">
        <label for="password">Password</label>
        <input [(ngModel)]="password" type="password" name="password" id="password" placeholder="Add your Password" autocomplete="off">
        
        <!-- HIDDEN INPUT WITH CSRF TOKEN -->
        <input type="hidden" name="csrfmiddlewaretoken" value="{{token}}">
    </div>
    
    <p (click)="switchView('reset')" class="text-right text-secondary font-bold font-main text-xs -mt-2 mb-3 lg:-mt-1 transition-transform hover:scale-95 cursor-pointer">Forgot Password?</p>
    <button class="button" [disabled]="!isEmpty" (click)="login()">{{loading ? 'Loading' : 'Log in'}}</button>
</form>

<ng-container *ngIf="error" >
    
    <div class="relative">
        <p class="absolute p-1 py-2 w-full text-xs text-center lg:text-darkgray -top-4 text-secondary font-semibold lg:font-normal bg-white lg:top-0">
            Username or password incorrect.
            <br>
            <span>
                Please refresh your browser and try again.
            </span>
        </p>
    </div>
    
</ng-container>

<div class="lg:mt-14">
    <p class="text-center text-secondary font-main text-xs lg:text-sm cursor-default">
        Don't have an account? 
        <span class="font-bold cursor-pointer hover:underline" (click)="switchView('new')">
            Create an account
        </span>
    </p>
</div>

