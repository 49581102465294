import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserIdleConfig, UserIdleService } from 'angular-user-idle';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  // SERVICE
  private idle:     UserIdleService = inject(UserIdleService);

  // STATE
  private active:   boolean         = false;

  // GETTERS
  public get isActive(): boolean {
    return this.active;
  }

  // METHODS
  /**
   * Cargamos la configuración del servicio de inactividad y lo iniciamos.
   */
  public start(configuration: UserIdleConfig): void {
    
    //Aplicamos configuración, activamos el servicio y nos suscribimos al evento de inicio del temporizador.
    this.idle.setConfigValues(configuration)        
    this.idle.startWatching();
    this.idle.onTimerStart().subscribe(() => {});

    //Indicamos que el servicio está activo.
    this.active = true;
  }

  /**
   * Detiene el servicio de inactividad.
   */
  public stop(): void {
    this.idle.stopWatching();
    this.active = false;
  }

  /**
   * Devuelve un observable que se activa cuando el tiempo de inactividad se cumple.
   * @returns 
   */
  public onTimeout(): Observable<boolean> {
    return this.idle.onTimeout();
  }

}
