import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private http:       HttpClient  = inject(HttpClient);
  private endpoint:   string      = '/customer/';

  /**
   * Función que inicia el procedimiento de registro de un usuario.
   * @param {string} email 
   * @returns Observable<Object>
   */
  public registerNewAccount(email: string): Observable<Object> {
    const body: any = { email };
    return this.http.post(
      this.endpoint + '/register/',
      body,
      {responseType: 'json'}
    )
  }

}
