<!-- TAKE OVER BANNER -->
<app-admin-banner
    *ngIf="admin.isAdmin" 
    [adminData]="admin.adminData"
    [client]="info.client"
/>

<!-- TOPBAR -->
<div class="fixed z-20 lg:static w-full flex justify-between lg:items-start p-4 lg:p-6 bg-white lg:bg-transparent dark:bg-gray-900 lg:border-none shadow-lg shadow-secondary/10 lg:shadow-none" [ngClass]="{'relative': admin.isAdmin}">
    
    <!-- CLIENT AND APP NAME -->
    <div class="hidden lg:block sticky top-0 text-xl xl:text-2xl font-medium text-darkgray dark:text-lightgray">
        <h1>
            {{info.client}}
            <span class="opacity-50">
            | {{info.appName}}
            </span>
        </h1>
    </div>

    <!-- USER DATA -->
    <div class="flex flex-col text-main dark:text-secondary text-[.70rem] lg:text-base justify-center w-full lg:w-auto">
        
        <!-- NAME AND SUPPORTER NUMBER -->
        <div class="flex justify-center">
            <h3 class="inline-block">
                Welcome, {{user.name}} {{user.surname}} &nbsp;
            </h3>
            <p class="font-bold inline-block">
                |&nbsp; Supporter Number: {{user.account}}
            </p>
        </div>

        <!-- LOYALTY POINTS AND MEMBERSHIPS (OPTIONAL) -->
        <ng-container *ngIf="hasLoyaltyOrMemberships()">
            <div class="hidden lg:flex w-full items-start" [ngClass]="userLoyaltyPoints() ? 'justify-between' : 'justify-end'">
                
                <!-- LOYALTY POINTS -->
                <ng-container *ngIf="userLoyaltyPoints()">
                    <div class="w-fit">
                        <p class="font-semibold inline-block text-sm text-main/60">
                         Loyalty Point Balance:&nbsp; {{ userLoyaltyPoints()?.toString() }}
                        </p>
                    </div>
                </ng-container>
                

                <!-- MEMBERSHIPS -->
                <ng-container *ngIf="userMemberships().length">
                    <div class="text-end">
                        <p *ngFor="let membership of userMemberships()">
                            <span class="text-sm lg:text-xs text-main/60">
                                {{ membership }}
                            </span>
                        </p>
                    </div> 
                </ng-container>

            </div>
        </ng-container>
    </div>

</div>

<!-- EMPTY SHELL -->
<div class="h-12 lg:hidden"></div>
