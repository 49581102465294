<ng-container *ngIf="response else wait">
    <div class="flex flex-col gap-y-5 lg:gap-y-8 w-full items-center">
    
        <p>
            {{response}}
        </p>
    
        <button class="button w-6/12" (click)="backToLogin()">
            Back to Login
        </button>
    
    </div>
</ng-container>
<ng-template #wait>
<div>
    <span class="font-main animate-pulse">Wait Please</span>
</div>
</ng-template>