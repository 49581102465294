import { Component, Signal, computed, inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { APP_CONFIG } from 'src/configuration/configuration';
import { Configuration } from 'src/app/shared/models/configuration.model';
import { User } from 'src/app/shared/models/user.model';
import { Topbar } from './topbar.model';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})

export class TopbarComponent {

  // SERVICES
  private   auth:          AuthService   = inject(AuthService);  // Servicio de Auth
  private   configuration: Configuration = inject(APP_CONFIG);   // Servicio de Configuración
  
  // APP INFO
  protected info:  Topbar['info']  = {
    appName: this.configuration.appName,  // Nombre de la App
    client:  this.configuration.clientName // Nombre del cliente
  };

  // USER DATA
  protected user:  Topbar['user']  = {
    name:        this.auth.userData()?.tdc_info.first_name,       // Nombre del Usuario
    surname:     this.auth.userData()?.tdc_info.last_name,        // Apellido del Usuario
    account:     this.auth.userData()?.tdc_info.id,               // ID de la cuenta
    loyalty:     this.auth.userData()?.tdc_info.loyalty_programs, // Programas de lealtad
    memberships: this.auth.userData()?.tdc_info.memberships       // Membresías
  }

  // ADMIN TAKEOVER
  protected admin: Topbar['admin'] = {
    isAdmin:    this.auth.userData()?.admin_takeover ? true : false, // TakeOver del User
    adminData:  this.auth.userData()?.admin_takeover as User['admin_takeover'] ?? {username: 'Administrator'} // Data del TakeOver
  }

  // COMPUTED SIGNALS

  /**
   * ### User Loyalty Points
   * Este método computado devuelve los puntos de lealtad del usuario. 
   * Si no tiene puntos, devuelve null.
   */
  public userLoyaltyPoints:       Signal<number| null> = computed(()=> this.user.loyalty.map(program => program.current_point_balance)[0] ?? null);

  /**
   * ### User Memberships
   * Este método computado devuelve las membresías del usuario.
   * Si no tiene membresías, devuelve un array vacío.
   */
  public userMemberships:         Signal<string[]>     = computed(()=> this.user.memberships.map(membership => membership.description) ?? []);

  /**
   * ### Has Loyalty Or Memberships
   * Este método computado devuelve si el usuario tiene puntos de lealtad o membresías.
   */
  public hasLoyaltyOrMemberships: Signal<boolean>      = computed(()=> (this.userLoyaltyPoints() !== null && this.userLoyaltyPoints()! > 0) || this.userMemberships().length > 0);
  
}
