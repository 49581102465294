import { Injectable , inject} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {

  private auth: AuthService = inject(AuthService);

  /**
   * ### CSFR Interceptor
   * Este interceptor se encarga de que, una vez logueados, todas nuestras peticiones se le añada la cabecera de autentificación 
   * así como el dato 'withCredentials', de este modo podremos hacer peticiones a la API. 
   * @param request 
   * @param next 
   * @returns 
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const headerName    = 'X-CSRFTOKEN';
    const token         = this.auth.tokenData();

    if(token !== null && !request.headers.has(headerName)){
      request = request.clone({headers: request.headers.set(headerName, token), withCredentials: true})
    }

    return next.handle(request);
  }
}

export const csrfInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: CsrfInterceptor,
  multi: true
}
