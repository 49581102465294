import { Component, Input, inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})

export class LogoutComponent {

  private  auth:  AuthService  = inject(AuthService);
  private  modal: ModalService = inject(ModalService);
  
  @Input() onlyLetter!: boolean;

  /**
   * Subscribe al método logout del Servicio Auth y hace refresh para limpiar 
   * valores en servicios, signals...etc
   */
  protected logout(): void {
    this.modal.createLogOutModal().subscribe(result => result.isConfirmed ?
        this.auth.logout().subscribe(()=> window.location.reload()) :
        false);
  }
}
