import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginNewAccountComponent } from './login-new-account/login-new-account.component';
import { LoginResetComponent } from './login-reset/login-reset.component';
import { FormsModule } from '@angular/forms';
import { LogoModule } from 'src/app/shared/components/globals/logo/logo.module';
import { LoginLayoutComponent } from 'src/app/shared/components/layout/login-layout/login-layout.component';
import { LoginAssociationComponent } from './login-association/login-association.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoginFormComponent,
    LoginNewAccountComponent,
    LoginResetComponent,
    LoginLayoutComponent,
    LoginAssociationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    LogoModule
  ],
  exports: [
    LoginComponent
  ]
})
export class LoginModule {}
