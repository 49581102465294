import { Component, EventEmitter, Output, inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoginViews } from '../login.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})

export class LoginFormComponent {

  private auth:     AuthService = inject(AuthService);
  private router:   Router      = inject(Router);

  public user:      string  = '';
  public password:  string  = '';
  public error:     boolean = false;
  public loading:   boolean = false;
  public token:     string  = this.auth.tokenData();
  
  @Output() changeView = new EventEmitter<LoginViews>()

  protected get isEmpty() {
    return this.user.length && this.password.length  ? true : false;
  }

  /**
   * Usando los datos generados por los inputs, usamos el user y el password
   * para lanzar una petición a la Api de Login. Si es correcto, nos envía al inicio de la app. 
   * Si no, vacia los campos y muestra un error en la template. 
   * @returns void
   */
  protected login(): void {
   // Si ya se ha lanzado petición, retorna para evitar múltiples llamadas
    if(this.loading){
      return;
    }
  
   this.loading = true;
   this.error   = false;
   
   this.auth.login(this.user, this.password).subscribe({
     next:   (v)  => this.router.navigate(['/']),
     error:  (e)  => this.showErrorMessage(),
     complete:()  => this.loading = false
   });
  }

  /**
   * Método para cambiar de vista a los distintos subcomponentes
   * @param {LoginViews} view - Código de la vista 
   */
  protected switchView(view: LoginViews){
    this.changeView.emit(view)
  }

  /**
   * Método llamado por el error del método Login. Si Login devuelve error este método se instancia. 
   * Instancia un temporizador por el cual se muestra un mensaje en la template y al finalizar, lo esconde. 
   */
  private showErrorMessage(): void {
    this.loading = false;
    this.error   = true;

    setTimeout(()=> this.error = false, 3500)
  }
}
