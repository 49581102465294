<a [routerLink]="slug" routerLinkActive="linkActive">
    
    <!-- WRAPPER -->
    <div class="flex gap-x-8 lg:gap-x-6 items-center mb-4 pl-1 lg:px-0 lg:mb-0 lg:py-2 group lg:hover:bg-secondary cursor-pointer">

        <!-- ICON -->
        <div id="icon" class="flex lg:min-w-[var(--navbar-width)] lg:justify-center items-center">
            <i>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg> 
            </i>
        </div>

        <!-- TEXT -->
        <div id="text" *ngIf="!onlyLetter">
            <p class="text-xl lg:text-base lg:uppercase lg:group-hover:scale-105 lg:transition-transform">
                {{slug | titlecase}}
            </p>
        </div>

    </div>
</a>