import { CanActivateFn, Router} from '@angular/router';
import { inject } from '@angular/core'
import { AuthService } from '../services/auth.service';

/**
 * ### Login Guard
 * Este guard es el encargado de gestionar la vista del Login. 
 * Para ello, de manera por defecto, verificará mediante el Auth Service que no exista un user
 * logueado en la aplicación. Si hay user, lo devuelve al Inicio, si no está logueado, deja acceder.
 * 
 * Además, sirve para redireccionar y mapear los tokens de las vistas reset password y new account, ambos subcomponentes
 * del Login Component. 
 * @param route 
 * @param state 
 * @returns 
 */
export const loginGuard: CanActivateFn = (route, state) => {
  
  const auth: AuthService = inject(AuthService)
  const router: Router    = inject(Router);
  
  switch(route.routeConfig?.path){
    //DEFAULT: Si hay user lo manda al Inicio, si no hay, deja acceder al login
    default:
      return auth.getUser().then(
        user => {
          if(user){
            router.navigate(['/']);
            return false;
          }else{
            return true;
          }
        },
        error => {
          console.log(error)
          return false;
        }
      )
      
    //RESET PASSWORD: Cuando el user viene redirigido desde el email, se renderiza el componente reset-password con el form habilitado.
    case "reset-password":
      router.navigate(['/login'],{queryParams:{view: 'reset', token: route.params['t']}});
      return true;
    
    case "association":
      router.navigate(['/login'],{queryParams:{view: 'association', token: route.queryParams['t']}});
      return true;
  }
  
};
