import { Component, inject, Input } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal.service';
import { ConfirmationModalParams } from 'src/app/shared/models/modal.model';
import { User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-admin-banner',
  templateUrl: './admin-banner.component.html',
  styleUrl: './admin-banner.component.css'
})
export class AdminBannerComponent {

  // INPUTS
  @Input({required: true}) public adminData!: User['admin_takeover']; // Datos del Admin
  @Input({required: true}) public client!:    string;                 // Nombre del cliente (para formalizar la url)

  // SERVICES
  private modal: ModalService = inject(ModalService);                 // Servicio de Modal

  // GETTERS
  /**
   * ### Admin Name
   * Este método devuelve el nombre del Admin.
   * Se usa para no polucionar el template con lógica de negocio.
   */
  public get adminName(): string {
    return this.adminData?.username ?? 'Admin';
  }

  // METHODS
  /**
   * ### Open Confirmation Modal
   * Método bindeado al boton Log Out del Admin Banner.
   * Este método crea un modal de confirmación para asegurarse de que el Admin quiere cerrar sesión.
   * Si el user confirma, se llama a la función backToBackOffice() para redirigir al backoffice.
   **/
  public openConfirmationModal(): void {

    const modalParams: ConfirmationModalParams = {
        title:      "Go back",
        content:    `<p class="font-main">You are going to return to the back office. <br> Are you sure?</p>`,
        textCenter:  true,
        onConfirm: ()=> this.backToBackOffice()
    }
        
    this.modal.createConfirmationModal(modalParams);

  }

  /**
   * ### Back To BackOffice
   * Método bindeado al boton Log Out del Admin Banner.
   * Este método formaliza la URL del backoffice y redirige a la página.
   * Para ello, llama a la función getBackOfficeEnv() para obtener el subdominio del backoffice.
   */
  public backToBackOffice(): void {

    const url = `https://private.${this.getBackOfficeEnv()}.3ddigitalvenue.com`;	
    window.open(url, '_self');

  }

  /**
   * ### Get BackOffice Env
   * Este método retorna la url del backoffice para el entorno actual.
   * Para ello, invoca una Regex para obtener el subdominio del entorno actual. (dev, pre, uat, local)
   * Si alguno de estos subdominios coincide, se devuelve el subdominio con el nombre del cliente.
   * 
   * #### Importante
   * Si el subdominio es 'uat', se devuelve 'pre' en su lugar.
   * 
   * Si el subdominio no coincide, es que el entorno es Producción, por lo que se devuelve solo el nombre del cliente.
   * @returns {string} - URL del backoffice para el entorno actual.
   * 
   * @example DEV -> dev-chelseafc.3ddigitalvenue.com -> dev-chelseafc
   * @example UAT -> uat-chelseafc.3ddigitalvenue.com -> pre-chelseafc
   * @example PROD -> chelseafc.3ddigitalvenue.com -> chelseafc
   */
  private getBackOfficeEnv(): string {
    
    const regex:  RegExp             = new RegExp('^(?:https?:\/\/)?(dev|pre|uat|local)-[a-zA-Z0-9.-]+'), // Regex para obtener el subdominio si lo hay
          result: string | undefined = window.location.href.match(regex)?.[1],                            // Primer resultado de la Regex Match Array ('dev', 'pre', 'uat', 'local')
          client: string             = this.client.toLocaleLowerCase().replace(/ /g, '');                 // Devuelve el cliente como url friendly (Chelsea FC -> chelseafc)
    
    return result ? 
    `${result !== 'uat' ? result : 'pre'}-${client}` : 
    `${client}`;
  }


}
