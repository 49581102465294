<!-- Send Email Form -->
<form *ngIf="!token" action="submit" class="flex flex-col form mt-4 h-full lg:h-2/4">
    <div class="text-darkgray mb-5">
        <h3 class="text-xs sm:text-sm mb-1">
            Enter your email adress
        </h3>
        <p class="text-xs lg:text-sm">You will receive an email if the account exists. Please check your inbox or spam and follow the instructions.</p>
    </div>
    <label for="email">Email</label>
    <input [(ngModel)]="email" class="mb-2" type="email" name="email" id="email" placeholder="Your email" pattern="'[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'">
    <button class="button" [disabled]="isEmailEmpty || loading" (click)="resetPassword()">{{loading ? 'Loading' : 'Send Email'}}</button>
</form>
<!-- Reset Password Form -->
<form *ngIf="token" action="submit" class="flex flex-col form mt-4 h-full lg:h-2/4">
    <div class="text-darkgray mb-5">
        <h3 class="text-xs sm:text-sm mb-1">
            Enter your new password.
        </h3>
        <p class="text-xs lg:text-sm">Please, enter and repeat a new password to update your account.</p>
    </div>
    <label for="email">New Password</label>
    <input [(ngModel)]="password" class="mb-6 outline-main" type="email" name="email" id="email" placeholder="Password" pattern="'[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'">
    <label for="email">Repeat Password</label>
    <input [(ngModel)]="newPassword" class="mb-9 outline-main" type="email" name="email" id="email" placeholder="Repeat the password" pattern="'[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'">
    
    <button class="button outline-secondary" [disabled]="!passwordsAreEqual || loading" (click)="saveNewPassword()">{{loading ? 'Loading' : 'Reset Password'}}</button>
</form>
<!-- Back to Login -->
<div>
    <button class="w-full text-main dark:text-secondary text-center font-main transition-transform hover:scale-95 outline-main" (click)="showLoginForm()">Back to login</button>
</div>