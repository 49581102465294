import { Component, Output, EventEmitter, inject } from '@angular/core';
import { LoginViews } from '../login.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login-reset',
  templateUrl: './login-reset.component.html',
  styleUrls: ['./login-reset.component.css']
})
export class LoginResetComponent {

  @Output() changeView = new EventEmitter<LoginViews>()

  private   auth:     AuthService    = inject(AuthService);
  private   router:   Router         = inject(Router);
  
  protected token:        string  = inject(ActivatedRoute).snapshot?.queryParams['token']
  protected loading:      boolean = false;
  
  protected email:        string  = '';
  protected password:     string  = '';
  protected newPassword:  string  = '';
  
  protected get isEmailEmpty(): boolean {
    return this.email.length <= 0 ? true : false;
  }

  protected get passwordsAreEqual(): boolean {
    return (this.password.length != 0 && this.newPassword.length != 0) && this.password === this.newPassword;
  }
  
  public showLoginForm(): void {
    this.changeView.emit('login')
  }

  protected resetPassword(): void {
    this.loading = true;
    this.auth.resetPassword(this.email).subscribe({
      next:   (v)=> this.email = '',
      error:  (e)=> console.log(e),
      complete:()=> this.loading = false
    })
  }

  protected saveNewPassword(): void {
    this.auth.saveNewPassword(this.token,this.password).subscribe({
      next:  ()=> {
        this.router.navigate(['/login']);
        this.showLoginForm();
      },
      error: (e)=> console.log(e)
    })
  }

}
