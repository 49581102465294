import { Component, EventEmitter, Output, inject } from '@angular/core';
import { LoginViews } from '../login.model';
import { RegisterService } from 'src/app/core/services/register.service';

@Component({
  selector: 'app-login-new-account',
  templateUrl: './login-new-account.component.html',
  styleUrls: ['./login-new-account.component.css']
})
export class LoginNewAccountComponent {
  
  @Output() changeView = new EventEmitter<LoginViews>();

  private register: RegisterService = inject(RegisterService);
  
  protected email:   string  = '';
  protected loading: boolean = false;

  /**
   * Getter que verifica si el campo Email está vacio.
   */
  protected get isEmailEmpty(): boolean {
    return this.email.length > 0 ? true : false;
  }

  /**
   * Función que lanza el procedimiento para crear una nueva cuenta.
   */
  protected createNewAccount(): void {
    this.loading = true;

    this.register.registerNewAccount(this.email).subscribe({
      next:  (v)=> console.log(v),
      error: (e)=> this.handleError(e),
    })

  }

  /**
   * Event Emitter por el cual cambiamos el form renderizado al del login. 
   */
  protected showLoginForm(): void {
    this.changeView.emit('login');
  }

  /**
   * Método que gestiona los códigos de error generados al iniciar la creación de una cuenta.
   * @param {HttpErrorResponse} error 
   */
  private handleError(error: any): void {
    const code = error?.error?.code;
    if(code){
      switch(code){
        default:
          alert('Code doesnt match');
          break;
        // The user exist in MMC PORTAL AND PV
        case 'CAEDB':
          alert('This user already exists')
          break;
        // The user not exists in TDC
        case 'CNETDC':
          alert('This user not exists in TDC')
          break;
      
      }
    }
  }

}
