import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar.component';
import { AdminBannerComponent } from './admin-banner/admin-banner.component';

@NgModule({
    declarations: [
        TopbarComponent,
        AdminBannerComponent
    ],
    imports: [CommonModule],
    exports: [TopbarComponent]
})
export class TopbarModule { }