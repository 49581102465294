import { Entity } from "src/app/shared/models/configuration.model";

export const configurationData: Entity = {
    'default': {
        clientName: 'MMC',
        appName:    "Protected Sales Portal",
        icon:       "/assets/favicon/favicon.ico",
        logo:       "/assets/img/default/logo.png",
        background: "/assets/img/default/background.jpg",
        theme:{
            app : {
                'main-color':       "89 65 169",
                'secondary-color':  "109 114 195",
                'darkgray':         "96 96 96",
                'lightgray':        "229 229 229"
            },
            dvm : {
                'main-color':       "89 65 169",
                'secondary-color':  "109 114 195",
                'selected-color':   "255 0 0"
            }
        },
        venue:{
            'RIVER CITY': {
                venueID: '',
                mapID:   '',
                name:    '',
                client:  '',
                has3d:   false
            }
        },
        membership: {
            RestrictedPriceScale: {},
            RestrictedBuyerType: {}
        },
        metadata: [
            {
                name: 'title',
                content: 'Protected Sales Portal'
            },
            {
                name: 'description',
                content: 'Official Ticketing Portal'
            },
            {
                name: 'robots',
                content: 'Index,follow'
            },
            {
                name: 'keywords',
                content: 'PSP, Ticketing, Official Portal, Buy Tickets'
            },
            {
                name: 'viewport',
                content: "width=device-width, initial-scale=1, maximum-scale=5"
            }
        ],
        idle: { active: false }
    },
    'chelseafc': {
        clientName:  "Chelsea FC",
        appName:     "Official Ticketing Portal",
        icon:        "/assets/img/chelsea/fav.ico",
        logo:        "/assets/img/chelsea/logo.png",
        background:  "/assets/img/chelsea/background.webp",
        background2: "/assets/img/chelsea/background2.webp",
        theme:{
            app: {
                'main-color':      "0 20 137",
                'secondary-color': "59 88 255",
                'darkgray':        "96 96 96",
                'lightgray':       "229 229 229"
            },
            dvm: {
                'main-color':      "0 20 137",
                'secondary-color': "59 88 255",
                'selected-color':  "255 193 7"
            }
        },
        venue:{
            "STAMFORD BRIDGE": {
                venueID:  "eu-gb-00018-chelseafc",
                mapID:    "blockmap",
                name:     "Stamford Bridge",
                client:   "tdc",
                has3d:    true
            },
            "KINGSMEADOW": {
                venueID:  "eu-gb-10094-chelseafcwomen",
                mapID:    "blockmap2d",
                name:     "Kingsmeadow",
                has3d:    false,
                client:   "tdc"
            }
        },
        metadata: [
            {
                name: 'title',
                content: 'Chelsea FC - Official Ticketing Portal'
            },
            {
                name: 'description',
                content: 'Chelsea Football Club Official Ticketing Portal for Supporters'
            },
            {
                name: 'robots',
                content: 'Index,follow'
            },
            {
                name: 'keywords',
                content: 'PSP,ChelseaFC, Football, Ticketing, Official Portal, Buy Tickets'
            },
            {
                name: 'viewport',
                content: "width=device-width, initial-scale=1, maximum-scale=5"
            }
        ],
        membership: {
            RestrictedPriceScale: {
                TEEN:   { from: '2004-08-01', until: '2013-07-31' },
                JUNIOR: { from: '2013-08-01', until: 'today' },
                AMTEEN: { from: '2004-08-01', until: '2013-07-31' },
                AMJUN:  { from: '2013-08-01', until: 'today' },
                NATEEN: { from: '2004-08-01', until: '2013-07-31' },
                NAJUN:  { from: '2013-08-01', until: 'today' },
            },
            RestrictedBuyerType: {
                TEENUK: { from: '2004-08-01', until: '2013-07-31' },
                TEEEUR: { from: '2004-08-01', until: '2013-07-31' },
                TEEROW: { from: '2004-08-01', until: '2013-07-31' },
                JUNIUK: { from: '2013-08-01', until: 'today' },
                JUNEUR: { from: '2013-08-01', until: 'today' },
                JUNROW: { from: '2013-08-01', until: 'today' },
                AMTEUK: { from: '2004-08-01', until: '2013-07-31' },
                AMTEEU: { from: '2004-08-01', until: '2013-07-31' },
                AMTERW: { from: '2004-08-01', until: '2013-07-31' },
                AMJUUK: { from: '2013-08-01', until: 'today' },
                AMJUEU: { from: '2013-08-01', until: 'today' },
                AMJURW: { from: '2013-08-01', until: 'today' },
                NATEUK: { from: '2004-08-01', until: '2013-07-31' },
                NATEEU: { from: '2004-08-01', until: '2013-07-31' },
                NATERW: { from: '2004-08-01', until: '2013-07-31' },
                NAJUUK: { from: '2013-08-01', until: 'today' },
                NAJUEU: { from: '2013-08-01', until: 'today' },
                NAJURW: { from: '2013-08-01', until: 'today' },
            },
        },
        exchange: {
            //Membership ids
            men_id:     '1217',
            women_id:   '1221'
        },
        idle: { 
            active: true, 
            options:{
                timeout: 1,
                idle: 1800,
                url: 'https://www.chelseafc.com/en/tickets/mens-tickets'
            }
        }
    }
}