import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LoginViews } from '../login.model';

@Component({
  selector: 'app-login-association',
  templateUrl: './login-association.component.html',
  styleUrl: './login-association.component.css'
})
export class LoginAssociationComponent implements OnInit {

  @Output()
  public changeView: EventEmitter<LoginViews> = new EventEmitter<LoginViews>()

  private http:    HttpClient       = inject(HttpClient)
  private params:  Params           = inject(ActivatedRoute).snapshot.queryParams;
  private token:   string|undefined = this.params['token'];

  public response!: string|undefined;

  ngOnInit(): void {
    this.initComponent();
  }

  public backToLogin(): void {
    this.changeView.emit('login')
  }

  private initComponent(): void {
    this.finalizeAddAssociation();
  }

  private finalizeAddAssociation(): void {
    const endpoint: string = '/customer/associations/add/finalize/';
    this.http.post(endpoint, {token: this.token}).subscribe({
      next:  (res:any) => this.response = 'Friend association created successfully',
      error: (err:any)=> this.response = err.error.message + '.',
    })
  }
}
