import { Injectable, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { APP_CONFIG } from 'src/configuration/configuration';
import { Configuration } from 'src/app/shared/models/configuration.model';
import { configurationData } from 'src/configuration/configurationData';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private configuration: Configuration = inject(APP_CONFIG);
  private document:      Document      = inject(DOCUMENT);
  private client!:       string;

  /**
   * ## Config Service
   * 
   * Esta función se ejecuta al instanciarse la app. Primero, ejecuta una expresión regular 
   * sobre window.location para devolver el cliente que está ejecutando la app a través del dominio.
   * Si la reg exp encuentra un client, lo devuelve y si no client se instancia como 'default'.
   * Una vez obtenida la llave, se copian los datos de configurationData con esa llave a nuestra inyeccion global
   * App_Config y se sobreescriben las variables de CSS del tema para adecuar los colores a cada proyecto, finalmente
   * se establece la ruta del favicon presente en el archivo de configuración.
   * 
   * #### Orden de los métodos
   * 1. Mediante una RegExp, conseguimos el nombre del dominio (ej: chelseafc, mkdons...)
   * 2. Seteamos como llave de la configuración el resultado obtenido por la regexp
   * 3. Una vez determinada que configuración usar, se rellena la inyección de Config con los datos de configurationData
   * 4. Seteamos variables css en el root
   * 5. Establecemos favicon
   * 
   * @returns {void} 
   */
  public init(): void {
    
    //REGEX PARA CONSEGUIR LLAVE
    const regex:  RegExp  = /^(?:http|https):\/\/(?:local\.)?(?:private\.)?(?:demo-)?(?:dev-)?(?:pre-)?(?:uat-)?(.*)\.3ddigitalvenue\.com.*/gm; //https://regex101.com/
    const client: string  =  regex.exec(location.href)?.[1] ?? 'default';

    this.client = client as keyof Configuration;

    //APLICAMOS DATOS A LA INYECCIÓN CONFIG
    const configuration: Configuration = configurationData![client];
    for(const [key, value] of Object.entries(configuration)){
        this.configuration[key as keyof Configuration] = value;
    }

    //SETEAMOS CSS
    const root: HTMLElement | null = this.document.querySelector(':root');
    if(root){
      const theme = this.configuration.theme.app;
      for(const [key, value] of Object.entries(theme)){
        root.style.setProperty('--' + key, value)
      }
    }

    //SETEAMOS FAVICON
    const favicon: HTMLLinkElement | null = this.document.getElementById('favicon') as HTMLLinkElement | null;
    if(favicon){
      favicon.href = this.configuration.assets.icon;
    }
    
  }

}
